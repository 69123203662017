/* Add styling for your page here */

.App {
  background:linear-gradient(pink, lightskyblue);
  display: flex;
  flex-direction: column;
}

h1 {
  text-align: right;
  margin-right: 1em;
  text-decoration: underline overline dotted rgb(255, 0, 157);
}

.KirbyItem {
  border: 5px inset #ddd;
  border-radius: 20px;
  padding: 0;
  width: 150px;
  align-self: center;
}

.kirby-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-left: auto;
  padding: 16px;
}
.kirby-items .window-body > * {
  margin: 0;
}
.window-body {
  display: flex;
  flex-direction: column;
  height: 210px;
  justify-content: space-between;
  gap: 1em;
}

.title {
  font-size: 40px;
  align-items: center;
}

.title-bar {
  height: 100px;
  width: auto;
}
